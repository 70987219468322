const skills = [
    {
        'name': 'Git',
        'prof': 75
    },
    {
        'name': 'Bootstrap',
        'prof': 70
    },
    {
        'name': 'HTML5',
        'prof': 80
    },
    {
        'name': 'Javascript',
        'prof': 75
    },
    {
        'name': 'React',
        'prof': 70
    },
    {
        'name': 'Python',
        'prof': 65
    },
];

export default skills;